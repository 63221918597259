@import '../../styles/design_system/colors';

.directory-filter {
  padding: 1rem 0rem 0rem;

  .btn:hover {
    border-color: $winred-red;
    background-color: $winred-red;
  }

  .btn {
    border-radius: 0rem;
    margin-right: 4px;
    margin-bottom: 4px;
    border: 1px solid #dee2e5;
    color: $mirage;
    box-shadow: none !important;
    transition: 0.2s;

    &:hover {
      border: 1px solid rgba(175, 189, 199, 0.6);
      color: initial;
      background: transparent;
    }
  }

  .btn-outline-danger,
  .btn-danger {
    border-color: $winred-red;
  }

  .btn-outline-danger:focus {
    box-shadow: none;
  }

  .btn-danger {
    background-color: $winred-red;
    border-color: $winred-red !important;
    color: $white;

    &:hover,
    &:active &:focus {
      border-color: $winred-red !important;
      background-color: $winred-red !important;
      color: $white !important;
    }
  }
}
.election-type,
.election-year {
  margin-top: 1em;
}

.election-type {
  margin-bottom: 1rem;
}

@media (max-width: 540px) {
  .btn-sm,
  .btn-group-sm > .btn {
    border-radius: 0 !important;
  }
}
