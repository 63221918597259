@import '../../../styles/design_system/colors';

.state-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .state-button {
    padding: 0.6em 0.7rem;
    background-color: $winred-red-dark;
    border: 1px solid $winred-red-dark;
    margin-left: auto;
    display: flex;
    justify-content: center;
    transition: 0.2s;
    box-shadow: none !important;

    &:hover,
    &:active {
      background-color: $winred-red !important;
      border-color: $winred-red !important;
    }
  }

  .state-selector-pop-up {
    outline: none;
  }

  .pop-up {
    margin-top: 20px;
    width: calc(100% - 30px);
    margin-right: 15px;
    padding: 15px;
    border: none;
    background-color: white;
    color: $mirage;
    position: absolute;
    right: 0;
    z-index: 100;

    .states-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .all-states-row {
      border: 1px solid $catskill;
      background-color: $catskill-white;
      padding: 2px;
      margin-bottom: 0.5em;
      width: 30%;
      text-align: center;
      cursor: pointer;
    }

    .state-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 1px solid $catskill;
      background-color: $catskill-white;
      margin: 1px;
      width: 10%;
      height: 32px;
      text-align: center;
      transition: 0.2s;

      &:hover {
        border: 1px solid rgba(175, 189, 199, 0.6);
      }

      &.selectable {
        cursor: pointer;
        background-color: $geraldine;
      }

      &.selected-state {
        background-color: $winred-red-dark;
        border: 1px solid $winred-red-dark;
        color: $white;

        &:hover {
          border: 1px solid $winred-red-dark;
        }
      }
    }
  }
}
