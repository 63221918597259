.btn-signup,
.btn-signin {
  opacity: 1;
  padding: 6.5px 9px !important;
  border: 1px solid #8dd1a4;
  margin: 17.5px 0 17.5px 10px;
  border-radius: 3px;
  font-size: 13px;
  transition: 0.2s;

  text-decoration: none !important;

  &:hover {
    background: #fff;
    color: #8dd1a4 !important;
  }
}

@media (max-width: 992px) {
  .btn-signup,
  .btn-signin {
    margin-left: 0px;
    text-align: center;
  }
}

.btn-signin {
  background: #ffffff;
  color: #8dd1a4 !important;
}

.btn-signup {
  background: #8dd1a4;
  color: #ffffff !important;
}
