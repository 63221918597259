// Adapted from: https://github.com/maman/JVFloat.js/blob/master/jvfloat.css
.float-label {
  position: relative;
}

.float-label .placeholder {
  color: $lynx;
  font-size: 0.5rem;
  line-height: 11px;
  position: absolute;
  top: 0.25rem;
  left: 1rem;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 150ms, opacity 100ms, visibility 100ms;
  transition: transform 150ms, opacity 100ms, visibility 100ms;
  opacity: 0;
  visibility: hidden;
  white-space: nowrap;
}

.float-label .placeholder.active {
  display: block;
  visibility: visible;
  -webkit-transform: translate(0, -3px);
  -moz-transform: translate(0, -3px);
  -o-transform: translate(0, -3px);
  -ms-transform: translate(0, -3px);
  transform: translate(0, -3px);
  -webkit-transition: -webkit-transform 100ms, opacity 120ms, visibility 120ms;
  transition: transform 100ms, opacity 120ms, visibility 120ms;
  opacity: 1;
}
