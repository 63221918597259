@import '../../../styles/design_system/colors';

h1 {
  font-size: 2rem;
  margin-bottom: 0px;
  color: $winred-red;
}

h2 {
  font-size: 24px;
  color: $winred-red;
  margin-bottom: 1rem;
}

label {
  margin-bottom: 0px;
}

.directory-side-bar {
  height: 100%;

  .sidebar-header {
    color: $winred-red-dark;
    word-wrap: break-word;
  }

  .scroll-butn-container {
    display: flex;
    justify-content: space-between;

    .scroll-btn {
      color: $white;
      background-color: $winred-red-dark;
      border-radius: 0.25rem;
      font-size: 18px;
      // white-space: nowrap;
      width: 48%;
    }
  }

  .sidebar-sections {
    max-height: 432px;

    .scroll-container {
      position: relative;
      overflow-y: scroll;
    }

    .list-group-item:first-child {
      border-radius: 0 !important;
    }

    // an office section
    .list-group-item {
      padding: 0px;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 1px solid rgba(175, 189, 199, 0.3);
      margin: 0px;

      &:last-of-type {
        border-bottom: 0px;
      }
    }

    .section-candidate {
      padding-bottom: 1rem;

      .candidate-avatar {
        border: 1px solid $mystic;
      }

      &:last-of-type {
        padding-bottom: 0rem;
      }

      .section-image {
        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
        }
      }

      .section-info {
        .name {
          font-weight: 600;
          text-transform: capitalize;
        }
        .office {
          color: $lynx;
        }
      }

      .section-btn {
        button {
          outline: 0;
          padding: 5px;
          background-color: $winred-red-dark;
          border-color: $winred-red-dark;
          transition: 0.2s;

          &:hover {
            background-color: $winred-red;
            border-color: $winred-red;
          }
        }
      }
    }
  }
}

.card-section {
  padding: 1rem;

  .header-section {
    min-height: 36px;
  }
}

.card-section-header {
  border-bottom: 1px solid rgba(175, 189, 199, 0.3);
}

.search-candidates {
  background-color: $rose-white;

  .search-icon {
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    left: 15px;
    z-index: 10;
    font-size: 1rem;
  }

  .winred-input {
    padding-left: 30px;
    border-radius: 0.25rem !important;
  }

  .close-btn {
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    right: 10px;
    z-index: 10;
    cursor: pointer;
    font-size: 1rem;
  }
}

.no-candidate-results {
  font-family: Roboto, sans-serif;

  .no-candidate-title {
    font-weight: 500;
    font-size: 14px;
    color: $winred-blue;
  }
  .no-candidate-details {
    color: $lynx;
  }
}

.side-bar-footer {
  a {
    text-decoration: underline;
  }
}
