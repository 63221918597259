/* Color Variables */
// http://chir.ag/projects/name-that-color/#3D59FF
$dodger-blue: #3d59ff;
$malibu: #6290ff;
$melrose: #b0c7ff;
$malibu-light: #89abff;
$moon-raker: #cedbf7;
$zircon: #f8faff;
$athens-gray: #eff1f4;
$winred-blue: #003060;
$winred-blue-light: #426488;
$winred-blue-hover: #5a7897;
$winred-red: #d0021b;
$winred-red-dark: #a10c2b;
$winred-green: #9ccfa7;
$winred-state-gray: #dee2e5;

$malachite: #0bc636;
$shamrock: #30dd78;
$algae-green: #9ee6c3;
$sea-green: #307963;
$magic-mint: #97edbb;
$pastel-green: #63e599;
$jagged-ice: #c4eadc;
$white-ice: #f7fefa;
$ice-white: #effdf5;

$mirage: #1e2b3a;
$pickled-bluewood: #243344;
$pickled-bluewood-light: #314459;
$plantation: #254349;
$lynx: #698099;
$matterhorn: #4f3747;
$soya-bean: #645c4e;

$nepal: #94aac1;
$mystic: #dbe1ec;
$catskill: #e9eef6;
$catskill-white: #f5f8fa;
$white: #ffffff;

$burnt-sienna: #ec5c5c;
$sunset-orange: #ff4d55;
$geraldine: #edafaf;
$rajah: #fabf68;
$banana: #fcdeb3;

$coral: #ff8b4d;
$peach-orange: #ffb994;
$whiskey: #d1a664;
$rose-white: #fff8f8;
$oyster-pink: #e7c6c8;
$hippie-pink: #ac4956;
$romance: #fffcf9;
$pearl-bush: #e6dbcc;

/* Legacy Colors */
$light-gray: #c5cbd1; // tinymce textbox border

$colors: (
  'dodger-blue': $dodger-blue,
  'melrose': $melrose,
  'malachite': $malachite,
  'moon-raker': $moon-raker,
  'zircon': $zircon,
  'shamrock': $shamrock,
  'magic-mint': $magic-mint,
  'pastel-green': $pastel-green,
  'sea-green': $sea-green,
  'mirage': $mirage,
  'pickled-bluewood': $pickled-bluewood,
  'pickled-bluewood-light': $pickled-bluewood-light,
  'plantation': $plantation,
  'lynx': $lynx,
  'matterhorn': $matterhorn,
  'soya-bean': $soya-bean,
  'nepal': $nepal,
  'mystic': $mystic,
  'catskill-white': $catskill-white,
  'white': $white,
  'sunset-orange': $sunset-orange,
  'geraldine': $geraldine,
  'rajah': $rajah,
  'banana': $banana,
  'coral': $coral,
  'peach-orange': $peach-orange,
  'whiskey': $whiskey,
  'rose-white': $rose-white,
  'oyster-pink': $oyster-pink,
  'hippie-pink': $hippie-pink,
  'romance': $romance,
  'pearl-bush': $pearl-bush,
  'burnt-sienna': $burnt-sienna,
  'winred-blue': $winred-blue,
  'winred-blue-light': $winred-blue-light,
  'winred-blue-hover': $winred-blue-hover,
  'winred-red': $winred-red,
  'winred-red-dark': $winred-red-dark,
  'winred-green': $winred-green,
);

// Creates utility classes for each color
// .c--{color-name}
// .bg--{color-name}
@each $name, $value in $colors {
  .c--#{$name} {
    color: $value;
  }
  .bg--#{$name} {
    background-color: $value;
  }
}

// export colors to use in js
:export {
  dodger-blue: $dodger-blue;
  melrose: $melrose;
  malachite: $malachite;
  moon-raker: $moon-raker;
  zircon: $zircon;
  shamrock: $shamrock;
  magic-mint: $magic-mint;
  pastel-green: $pastel-green;
  sea-green: $sea-green;
  mirage: $mirage;
  pickled-bluewood: $pickled-bluewood;
  pickled-bluewood-light: $pickled-bluewood-light;
  plantation: $plantation;
  lynx: $lynx;
  matterhorn: $matterhorn;
  soya-bean: $soya-bean;
  nepal: $nepal;
  mystic: $mystic;
  catskill-white: $catskill-white;
  white: $white;
  sunset-orange: $sunset-orange;
  geraldine: $geraldine;
  rajah: $rajah;
  banana: $banana;
  coral: $coral;
  peach-orange: $peach-orange;
  whiskey: $whiskey;
  rose-white: $rose-white;
  oyster-pink: $oyster-pink;
  hippie-pink: $hippie-pink;
  romance: $romance;
  pearl-bush: $pearl-bush;
  burnt-sienna: $burnt-sienna;
  winred-blue: $winred-blue;
  winred-blue-light: $winred-blue-light;
  winred-blue-hover: $winred-blue-hover;
  winred-red: $winred-red;
  winred-red-dark: $winred-red-dark;
  winred-green: $winred-green;
  winredstategray: $winred-state-gray;
}
