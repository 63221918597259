@import '../../../styles/design_system/colors';

.directory-map {
  path {
    pointer-events: all;
    transition: 0.2s;
  }

  path.unfiltered-state:hover {
    cursor: pointer;
    fill: $winred-red;
  }
}
