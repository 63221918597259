@import 'design_system/colors';
@import 'design_system/molecules';
@import 'design_system/float_label';

$primary: $winred-blue;
$secondary: $winred-green;
$success: $winred-green;

$theme-colors: (
  'cancel': $winred-red,
  'error': $winred-red,
  'pro': $mirage,
  'orange': $rajah,
);

$font-family-sans-serif: Open Sans, sans-serif;
$font-size-base: 0.875rem; // 14px
$h1-font-size: 2rem; // 36px

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 80%,
);

$body-bg: #ffffff;
$body-color: $mirage;

$border-color: $catskill;

$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 1rem;
$input-btn-font-family: sans-serif;
$input-btn-line-height: 1.25;
$input-btn-focus-width: 1px;
$input-disabled-bg: $white;

$nav-link-padding-y: 0.625rem; // 10px
$nav-link-padding-x: 0.625rem; // 10px

$navbar-padding-y: 0; // 10px
$navbar-padding-x: 0; // 10px

// messy, but effectively moves the header row text to the bottom without shrinking the row height
$table-head-cell-padding: 2.9rem 0rem 0.75rem 1.45rem;
$table-bg: $white;
$table-head-bg: $body-bg; // doesn't seem to do anything
$table-border-color: $catskill;

$badge-font-size: 12px;

$progress-height: 2px;

// this is the value from the example, but not used in colors?
$progress-bg: #eceeef;

$card-spacer-x: 1.5rem;
// Override the default button padding
$btn-padding-y: 1rem;
$btn-padding-x: 1rem;
$btn-padding-y-lg: 1rem;
$btn-padding-x-lg: 1rem;

// Override the default large button line height
$btn-line-height-lg: 1.2;
// Override the default button font weight
$btn-font-weight: 500;

// Override the default bold font weight
$font-weight-bold: 500;

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

/* Override the default form-control padding. This is copied from bootstrap and
   the last item added in has been changed from 2px to 1rem. */
$input-height: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2} + #{1rem});
$input-height-lg: calc(#{$input-line-height-lg * 1em} + #{$input-btn-padding-y-lg * 2} + #{0.8rem});

/* Add overrides to the max-height just for button dropdowns in input groups. */
.input-group .dropdown {
  .dropdown-toggle {
    height: $input-height;
    max-height: $input-height;
  }
}

// Override the default table row hover background color.
.table-hover tbody tr:hover {
  background-color: #f5f8fa;
}

.card-footer {
  border-color: #e9eef6;
}
