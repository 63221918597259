/* Blocks */

.winred-input {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.75;
  padding-bottom: 0.25rem;
  border: 1px solid $catskill;
  color: $pickled-bluewood-light;
  background-color: $white;
  color: $winred-blue;

  &:disabled {
    color: $mystic;
    background-color: $white;
    border: 1px solid $catskill;
  }
}

.winred-input-select {
  padding-bottom: 0px;
  border: none;

  input {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
  }

  &.winred-input--error {
    border-radius: 4px;
  }
}

/* Modifiers */

.winred-input--active {
  &::-webkit-input-placeholder {
    color: $winred-blue;
  }
  &::-moz-placeholder {
    color: $winred-blue;
  }
  &:-ms-input-placeholder {
    color: $winred-blue;
  }
  &:-moz-placeholder {
    color: $winred-blue;
  }
}
