@import '../../styles/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

/** nav items */
.winred-nav__nav-item {
  white-space: nowrap;

  &:focus {
    border: none;
    outline: none;
  }

  &:first-child {
    margin-left: 0;
  }

  .winred-nav__nav-link {
    color: #485057 !important;
    font-size: 13px;
    transition: 0.2s;

    &:hover {
      color: #c45a6b !important;
    }
  }
}

@media (min-width: 991px) {
  .winred-nav__nav-item {
    .winred-nav__nav-link {
      padding: 26px 20px 26px 20px !important;
    }
  }
}

.winred-nav__nav-item--active {
  .winred-nav__nav-link {
    color: #b0334c !important;

    svg {
      color: $winred-red !important;
    }
  }
}

.winred-nav__nav-item--hover {
  .title-container {
    color: rgba(0, 0, 0, 0.7);
  }
}

// @include media-breakpoint-down(md) {
//   .winred-nav {
//     box-shadow: 0;

//     /** nav items */
//     .winred-nav__nav-item {
//       box-shadow: 0px 0px;
//       padding: 0px !important;
//       margin-right: 0px;
//       margin-left: 0;

//       .nav-link {
//         background: transparent;
//         margin: 0rem 1rem;
//         padding-left: 0;
//         padding-right: 0;
//         color: $pickled-bluewood-light;
//       }

//       &:hover {
//         box-shadow: 0;
//       }
//     }

//     .winred-nav__nav-item--active {
//       box-shadow: none; // removes the underline on the active item

//       .winred-nav__nav-link {
//         color: $pickled-bluewood-light;
//       }
//     }
//   }

//   .winred-nav__menu-nav-item {
//     &:not(.mobile) {
//       display: none;
//     }

//     &.mobile {
//       display: block;
//     }
//   }
// }
