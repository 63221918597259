body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Open Sans', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import 'styles/variables';

@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/mixins/_breakpoints';

.main-data-spinner {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: 80px;

  &.spinner-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
  }

  .spinner-border {
    height: 4rem;
    width: 4rem;
  }
}
