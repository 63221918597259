@import '../../styles/design_system/colors';

.footer-top {
  border-top: 1px solid rgba(175, 189, 199, 0.3);
  background: #fff;

  .footer-logo {
    padding: 23.5px 0px;
    display: block;
  }
}

.footer-item {
  display: inline-flex;
}

.footer-link {
  color: #485057 !important;
  font-size: 13px;
  padding: 26px 20px !important;
  transition: 0.2s;
  display: block;

  &:hover {
    color: #c45a6b !important;
    text-decoration: none;
  }
}

.footer-link-active {
  color: #c45a6b !important;
}

.footer-bottom {
  background-color: $mirage;
  padding: 30px 0px 10px;
  color: $white;
  font-size: 12px;

  .copyright {
    padding: 10px 15px;
    margin-bottom: 20px;
  }

  a,
  .text-footer {
    color: $white;

    &:hover {
      color: $white;
    }
  }

  .footer-social-icons {
    svg {
    }
  }
}

.full-width {
  width: 100%;
}
