@import '../../styles/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.winred-nav-logo {
  padding-left: 0rem !important;
}

.top-nav-bar {
  padding-left: 0;
  padding-right: 0;
  background-color: $white;
  .mainnav {
    border-bottom: 1px solid rgba(175, 189, 199, 0.3);
  }
  .subnav {
    background-color: $catskill-white;
    box-shadow: inset 0 -2px 0 0 $catskill;
  }
}

// @include media-breakpoint-down(md) {
//   .top-nav-bar .container {
//     padding: 0;
//   }

//   .winred-nav {
//     box-shadow: 0;

//     .title-container {
//       display: flex;
//       flex-direction: row;
//       justify-content: space-between;
//       align-items: center;
//       padding: 0;
//     }
//   }
// }
